import './Modal.css';
import { Autocomplete, Button, Checkbox, Paper, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { useFormFields } from "../libs/hooksLib";

export default function Modal({ handleClose, show, handleSave, children, assetTypes}) {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  const [fields, handleFieldChange] = useFormFields({
    pType: "0",
    pName: "",
    pDescription: "",
  });


  const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }))(InputBase);

  const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
  }));


  const handleOptionsChange = (event) => {
    console.log(event.target)
    fields.pType = event.target.value;
    handleFieldChange()

    //setWhen(event.target.value);
  }
  
  return (
    

    <div className={showHideClassName}>
      <section className="modal-main">






      <Box
				zIndex={5} sx={{width: '700px' }}
 			>
				<Paper elevation={3} >
					<Box sx={{bgcolor: "#10385A", borderRadius: 1, height: 47, textOverflow: 'ellipsis' }}>
						<Box px={1} pt={1} >
							<Typography variant="h6" nowrap style={{  color: 'white', overflow: "hidden",textOverflow: 'ellipsis' }} gutterBottom>
								{/*{this.props.title}*/}
                                Create New Project
							</Typography>
						</Box>
					</Box>
					<div style={{ display: 'flex', gap: '12px', height: 'Calc(100% - 100px)', margin: '10px' }}>
						<div className='infoDiv' style={{ display: 'flex', flexDirection: 'column', flex: 1, margin: 10, overflowY: 'scroll' }}>
							
                        <div style={{ marginBottom: 15 }}>





													<Box ml={1} style={{ fontWeight: 'bold' }}>Asset Type:</Box>
                        <Select
                        fullWidth
          id="pType"
          name="pType"
          label="pType"
          value={fields.pType}
          onChange={handleOptionsChange}
          input={<BootstrapInput />}
        >
          <MenuItem value="0">
            <em>Unset</em>
          </MenuItem>
          <MenuItem value={assetTypes[0].id.toString()}>{assetTypes[0].name}</MenuItem>
          <MenuItem value={assetTypes[1].id.toString()}>{assetTypes[1].name}</MenuItem>
          <MenuItem value={assetTypes[2].id.toString()}>{assetTypes[2].name}</MenuItem>
        </Select>
        </div>
                        <div style={{ marginBottom: 15 }}>





													<Box ml={1}  style={{ fontWeight: 'bold' }}>Project Name:</Box>
													<TextField
                                                        id="pName"
														fullWidth
														name="pName"
														required
														value={fields.pName}
														onChange={handleFieldChange} 
                                                        type="text" 
                                                        variant="outlined" 
                                                        size="small"/>
												</div>
                                                <div style={{ marginBottom: 15 }}>
													<Box ml={1}   style={{ fontWeight: 'bold' }}>Description:</Box>
													<TextField
                                                        id="pDescription"
														fullWidth
														name="pDescription"
														required
														value={fields.pDescription}
														onChange={handleFieldChange} 
                                                        type="text" 
                                                        variant="outlined" 
                                                        size="small"/>
												</div>
							
						{ /*


							{this.props.layer.fields.map((field, index) => {
								let def = 0
								if (field.type === 'string') { def = "" }
								if (field.defaultValue) {
									def = field.defaultValue
								}
								if (this.props.domainList && this.props.domainList.length > 0) {
									if (this.props.domainList.indexOf(def) < 0) {
										def = this.props.domainList[0]
									}
								}

								let opts = []
								if (field.domain && !this.props.hideList.includes(field.name)) {
									let domName = field.domain.name.replace(/ /g, "_")
									opts = field.domain.codedValues.map((item) => ({ label: t(`domains:${domName}.${item.code}`), id: item.code }))
								}
								if (field.name == this.props.inDomainName) {
									opts = this.props.inDomain
								}

								return (

									field.editable && (field.type == "string" || field.type == "integer" || field.type == "double" || field.type == "small-integer") &&
										!this.props.hideList.includes(field.name) &&
										!field.domain ?

										<div key={index} style={{ marginBottom: 5 }}>
											<div style={{ fontWeight: 'bold' }}>{t(`tables:${this.props.table}.${field.name}`)}:</div>
											<TextField
												id={field.name}
												name={field.name}
												required={!field.nullable}
												multiline={this.props.multiLine && this.props.multiLine.includes(field.name) && field.type == 'string'}
												maxRows={10}
												style={{ width: "100%" }}
												value={this.props.feature[field.name] ? this.props.feature[field.name] : field.defaultValue ? def : ""}
												onChange={this.props.onChange}
												type={field.type == "string" ? "text" : "number"} variant="outlined" size="small"
											/>
										</div>

										: field.editable && (field.type == "small-integer" || field.type == "string" || field.type == "guid") && !this.props.hideList.includes(field.name) && (field.name == this.props.inDomainName || (field.domain && field.domain.codedValues)) ?
											((field.domain && field.domain.codedValues?.length > 2) || field.name == this.props.inDomainName) ?

												<div key={index} style={{ marginBottom: 5 }}>
													<div style={{ fontWeight: 'bold' }}>{t(`tables:${this.props.table}.${field.name}`)}:</div>
													{ }
													<Autocomplete
														isOptionEqualToValue={(option, value) => option.id === value.id}
														disablePortal
														name={field.name}
														required={!field.nullable}
														options={opts}
														getOptionLabel={(option) => option.label}
														id={field.name}
														defaultValue={def}
														onChange={(event, values) => { this.props.onChange(event, values, field.name) }}
														value={this.props.feature[field.name] ? opts.find((opt) => opt.id === this.props.feature[field.name]) : opts.find((opt) => opt.id === def)}
														fullWidth
														renderOption={(props, option) => {
															return (
																<li {...props} key={option.id}>
																	{option.label}
																</li>
															);
														}}
														renderInput={(params) => <TextField {...params} />}
													/>

												</div>
												: !this.this.props.hideList.includes(field.name) && <div key={index} style={{ marginBottom: 5 }}>
													<div style={{ fontWeight: 'bold' }}>{t(`tables:${this.props.table}.${field.name}`)}:</div>
													<Checkbox
														required={field.nullable}
														id={field.name}
														name={field.name}
														checked={this.props.feature[field.name] ? this.props.feature[field.name] : field.defaultValue ? field.defaultValue : ""}
														onChange={this.props.onChange}
													/>
												</div>
											: field.editable && !this.props.hideList.includes(field.name) && field.type == "date" ?
												<div key={index} style={{ marginBottom: 5 }}>
													<div style={{ fontWeight: 'bold' }}>{t(`tables:${this.props.table}.${field.name}`)}:</div>
													<TextField
														fullWidth
														name={field.name}
														required
														id={field.name}
														type="datetime-local"
														value={this.props.feature[field.name] ? this.formatDate(this.props.feature[field.name]) : 0}
														onChange={this.props.onChange} />
												</div>
												:
												<></>)

							})}
							{this.props.subForm && this.props.subForm}
						*/
					}
						</div>
					</div>
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        
						<Button onClick={handleClose}>Cancel</Button>
						<Button onClick={()=>handleSave(fields)}>Save</Button>
                
					</div>
				</Paper>
			</Box>





        {children}
      </section>
    </div>
  );
};
