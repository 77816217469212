import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useAppContext } from "../libs/contextLib";
import { useHistory } from "react-router-dom";
import LoaderButtonX from "../components/LoaderButtonX";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import image from'../images/splash01.jpg';
import iCampra from'../images/campra/Campra Accredited - Green.svg';
import iSFS from'../images/SFS/sfs_logo_full.png';
import "./About.css";


const FooterTypography = withStyles({
  root: {
    color: "#fff"
  }
})(Typography);



export default function About() {
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
      const handleWindowResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
  }

  const { width } = useViewport();
  const breakpoint = 600;

  function handleClick(event) {
    event.preventDefault();
    history.push("/signup");
  }

  function handleContactClick(event) {
    event.preventDefault();
    history.push("/contact", { from: 'about' } );
  }


  function renderLander() {
    return (
      <>
      <img src={image} alt="FinDigAI" width="100%" height="auto"/>
      <div className={width>breakpoint?"lander":"mobile-lander"} >


      <h1 style={{color:"#10385A"}}>About FinDigAI<sup>®️</sup>,</h1>
      <h1 style={{color:"#F18C7E"}}>Intelligent Financial Reporting.</h1>
      <br/>
<p>We...</p>

</div>






<Box
position="sticky"
bottom="0px"
left="0px"
right="0px"
display="flex"
flexDirection="column"
justifyContent="center"
alignItems="center"
width="100%"
bgcolor="#10385A">
<Divider width="100%" style={{ background: '#fff' }}/>

<Box display="flex" p={1} width="100%">

<Box p={0} flexGrow={1}>
<FooterTypography variant="caption">FinDigAI<sup>®️</sup> Limited<br/> {/* ® */}
Company No. ABC12345<br/>
<Link style={{color:"white"}} onClick={handleContactClick} >Contact us</Link>
</FooterTypography>
</Box>
<Box p={0} display="flex" flexDirection="column">
<a style={{color:"white", fontSize:"12px", textAlign:"right"}} href={"https://findigAI.com/docx/faqs.pdf"} target="_blank">FAQs</a>
<Box style={{textAlign:"right"}} py={0.5}><a style={{color:"white", fontSize:"12px", textAlign:"right"}} href={"https://findigAI.com/docx/privacy.pdf"} target="_blank">Privacy</a></Box>
<a style={{color:"white", fontSize:"12px", textAlign:"right"}} href={"https://findigAI.com/docx/tncs.pdf"} target="_blank">Terms of Use</a>
</Box>
</Box>
<Box width="100%" mt={-0.8} pl={1} style={{textAlign:"left"}}><a style={{color:"#ccc", fontSize:"10px", textAlign:"left"}} >©2023 findigAI Limited. All rights reserved.</a></Box>
</Box>









</>




    );




  }




  return (
    <div className="About">
      {renderLander()}
    </div>
  );
}
