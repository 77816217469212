import React, { useState , useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
//import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
//import DirectionsIcon from '@material-ui/icons/Directions';

const useStyles = makeStyles((theme) => ({
  root: {
    opacity: 0.8,
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '240px',

  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,

    fontFamily: 'Montserrat'
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  paper: {
    opacity: 0.5,
  }
}));

export default function SiteIdInput(props) {
  const [formData, setFormData] = useState({ siteid: '', other: '' });
  const handleChange = e => setFormData({ ...formData, [e.target.name]: e.target.value.toUpperCase()});

  useEffect(() => {
    if (props.onChange) {
      props.onChange(formData)
    }
  }, [formData.siteid, formData.other])




  const [input, setInput] = useState('');
  const classes = useStyles();


  const onChange = (e) => {
      setInput(e.currentTarget.value);
  };

  return (
    <Paper className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder="Enter Company Code"
        inputProps={{ 'aria-label': 'enter company code' }}
        name="siteid"
        value={formData.siteid}
        onChange={handleChange}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            ev.preventDefault();
            if (formData.siteid.length>5) {
              //props.onClick();
            }
          }
        }}
      />
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton color="primary" className={classes.iconButton} aria-label="directions" onClick={console.log("aaa")/*props.onClick*/} disabled={formData.siteid.length<6} >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
