import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


const colors = ['#00C455', '#FFBB28', 'red'];
const CustomTooltip = ({ active, payload, label }) => {

  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        
        <Paper elevation={3} >
        <Box p={0.5}  align="center">
        <Typography variant="caption"  color="primary">{`${payload[0].payload.name}: `}<b>{`${payload[0].value}/9`}</b></Typography>
        </Box>
        </Paper>
      </div>
    );
  }

  return null;
};

export default function FDBarChart(props) {
let dataArr = [];

Object.keys(props.data).map(function(key) {
  dataArr.push({
    name: key,
    score: props.data[key],
  });
});
  
    return (
      <>
      {/*<Button onClick={() => props.yourFunction("lksdjchsalkvh")}>SomeButton</Button>*/}
      <ResponsiveContainer width="100%" height={30}>
        <BarChart width={10} height={4} data={dataArr}>
          <Tooltip  wrapperStyle={{top: -40, left: 0, width: "140%" }}  content={<CustomTooltip />} />
          <Bar dataKey="score" fill="#8884d8" >
          {dataArr.map((entry, index) => (
            
          <Cell key={`cell-${index}`} fill={colors[entry.score<4?2:entry.score<7?1:0]} />
        ))}
            </Bar>
        </BarChart>
      </ResponsiveContainer>
      </>
    );
  }

