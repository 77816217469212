import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import About from "./containers/About";
//import Hosting from "./containers/Thinking";
import Login from "./containers/Login";
import Account from "./containers/Account";
//import Transactions from "./containers/Trans";
import Manager from "./containers/Manager";
import Signup from "./containers/Signup";
//import NewSite from "./containers/NewSite";
//import EditSite from "./containers/EditSite";
//import Shop from "./containers/Shop";
//import Site from "./containers/Site";
//import SiteA from "./containers/Site";
import Contact from "./containers/Contact";
//import CampraContact from "./containers/CampraContact";
//import SiteContact from "./containers/SiteContact";
//import ContactA from "./containers/Contact";
//import Sites from "./containers/Sites";
//import VisitorBook from "./containers/VisitorBook";
//import Sign from "./containers/Sign";
//import Reauth from "./containers/Reauth";
//import Onboard from "./containers/Onboard";
//import Mapper from "./containers/Mapper";
/*import PaymentFailure from "./containers/PaymentFailure";
import PaymentSuccess from "./containers/PaymentSuccess";*/
import NotFound from "./containers/NotFound";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";


export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <UnauthenticatedRoute exact path="/about"><About /></UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/login"><Login /></UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/forgot"><Manager /></UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/signup"><Signup /></UnauthenticatedRoute>
     {/* <UnauthenticatedRoute exact path="/sites/map"><Mapper /></UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/sites/:id"><Site /></UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/sign"><Sign /></UnauthenticatedRoute>
  <UnauthenticatedRoute exact path="/hosting"><Hosting /></UnauthenticatedRoute>*/}
      <UnauthenticatedRoute exact path="/contact"><Contact /></UnauthenticatedRoute>
      {/*
      <UnauthenticatedRoute exact path="/campra-ref"><CampraContact /></UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/contact/:id"><SiteContact /></UnauthenticatedRoute>

      <AuthenticatedRoute exact path="/contactus"><Contact /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/campra/:id"><Site /></AuthenticatedRoute>

      <AuthenticatedRoute exact path="/admin/transactions"><Transactions /></AuthenticatedRoute>
*/}
      <AuthenticatedRoute exact path="/account"><Account /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/account/edit"><Manager /></AuthenticatedRoute>
{/*
      <AuthenticatedRoute exact path="/sites"><Sites /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/visitors"><VisitorBook /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/sites/create/new"><NewSite /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/sites/:id/edit"><EditSite /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/shop"><Shop /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/reauth"><Reauth /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/onboard"><Onboard /></AuthenticatedRoute>*/}

      {/*<UnauthenticatedRoute exact path="/pay0"><PaymentFailure /></UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/pay1"><PaymentSuccess /></UnauthenticatedRoute>*/}

{/*
      <AuthenticatedRoute path="/stripe" exact component={() => window.location = ''} props={childProps}/>
      */}


      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
