import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";

import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import "./Signup.css";
import { Auth } from "aws-amplify";

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Checkbox from '@material-ui/core/CheckBox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

import LoaderButtonX from "../components/LoaderButtonX";


import CircleIcon from '@material-ui/icons/FiberManualRecord';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const FooterTypography = withStyles({
  root: {
    color: "#fff"
  }
})(Typography);

const useStyles = makeStyles((theme) => ({
  link: {
      color: "#F18C7E",
      "&:hover": {
          color: "#F18C7E",
          textDecoration: "underline #F18C7E"
      }
    }

}));


export default function Signup() {
  let classes = useStyles();
  const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
  const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
  const [fields, handleFieldChange] = useFormFields({
    name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
  });
  const history = useHistory();
  const [tncs, setTncs] = useState(false);
  const [pwColor, setPwColor] = useState("#DB4437");
  const [newUser, setNewUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertVals, handleAlertChange] = useFormFields({
    title: "",
    message: "",
  });


  function validateForm() {
    let onlyNumbers = fields.phone.replace(/\D/g, '');
    return (
      fields.name.length > 1 &&
      fields.email.length > 4 &&
      fields.phone.length > 5 && onlyNumbers===fields.phone &&
      fields.password.length > 1 &&
      tncs
    );
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0;
  }
  function handleAlertClose() {
    setAlertOpen(false);
  };
  const tandcs = (event) => {
    //event.preventDefault();
    window.open("https://findigai.com/docx/tncs.pdf");
  };

  const handleTncsChange = (event) => {
    //event.preventDefault();
    setTncs(event.target.checked);
  }

  function pwStrengthCheck(event) {
    if(strongRegex.test(event.target.value)) {
        setPwColor("#0F9D58");
    } else if(mediumRegex.test(event.target.value)) {
      setPwColor("#F4B400");
    } else {
      setPwColor("#DB4437");
    }
    handleFieldChange(event);
  }


  function pwStrengthOk() {
    return strongRegex.test(fields.password);
  }
  function pwMatchOk() {
    return fields.password.length>7 && fields.confirmPassword.length>7 && fields.password===fields.confirmPassword;
  }
  function pwAllOk() {
    return pwStrengthOk() && pwMatchOk();
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (!pwStrengthOk()) {
      alertVals.title="FinDigAI - Oops!";
      alertVals.message=<>Your password strength is not sufficient. Your password should have:<br/>- a minimum of 8 characters<br/>- at least one special character e.g.: !@#$%ˆ&*<br/>- at least one number<br/>- at least one upper case letter<br/>- at least one lower case letter</>;
      setAlertOpen(true);
      return;
    }
    if (!pwMatchOk()) {
      alertVals.title="FinDigAI - Oops!";
      alertVals.message="Your passwords do not match. Please retype.";
      setAlertOpen(true);
      return;
    }





    setIsLoading(true);

    try {
      const newUser = await Auth.signUp({
        username: fields.email,
        password: fields.password,
        attributes: {
          'email': fields.email,
          'custom:name': fields.name,
          'custom:phone': fields.phone
        }
      });
      setIsLoading(false);
      setNewUser(newUser);
    } catch (e) {
      alertVals.title="FinDigAI - Oops!";
      alertVals.message=e.message;
      setAlertOpen(true);
      setIsLoading(false);
    }
  }


  function renderConfirmationForm() {
    return (

<div className="lander">
  <Box display="flex" flexDirection="column" flexWrap="nowrap" my={0}  mx={2} alignItems='top'>
  <Box display="flex" flexWrap="wrap" mb={0}  mx={0} alignItems='top'> <h3>Registration</h3>  </Box>
  <Box mx={0.5}>
    <p>Account with email <b>{fields.email}</b> successfully created!</p>
  </Box>
  </Box>
        <Form >
        <a style={{color: 'green'}}>Thank you, an email with a link to confirm your email address <b>{fields.email}</b> has been sent to you. Please open this email and click on the link to confirm your email address.</a>
        <br/>
        </Form>

</div>




    );
  }

  function renderForm() {
    return (
      <div className="lander">
      <Box display="flex" flexDirection="column" flexWrap="nowrap" my={0}  mx={2} alignItems='top'>
      <Box display="flex" flexWrap="wrap" mb={0}  mx={0} alignItems='top'> <h3>Signup</h3>  </Box>
      <Box mx={0.5}>
        <p>Ready for more intelligent financial reporting? Sign up here to get started.</p>
        <p>View the <a href={"https://findigai.com/docx/registration_guide.pdf"} target="_blank" style={{color:"#F18C7E"}}><u>Registration Guide</u></a> to help you through the process.</p>
      </Box>
      </Box>




      <Form onSubmit={handleSubmit}>
      <Box my={1} >
      <TextField id="name"
      autoFocus
      style={{width:"100%"}}
      value={fields.name}
      onChange={handleFieldChange}
      name="name"
      label="*Name" type="text" variant="outlined" size="small"/>
      </Box>
      <Box my={1}>
      <TextField id="email"
      style={{width:"100%"}}
      value={fields.email}
      onChange={handleFieldChange}
      name="email"
      label="*Email" type="email" variant="outlined" size="small"/>
      </Box>
      <Box my={1}>
      <TextField id="phone"
      style={{width:"100%"}}
      value={fields.phone}
      onChange={handleFieldChange}
      name="phone"
      pattern="\d+"
      label="*Phone Number" type="number" variant="outlined" size="small"/>
      </Box>
      <Box pb={1}>
<Paper variant="outlined">
<Box p={1}>
<Box pb={1}>
<TextField id="password"
style={{width:"100%"}}
value={fields.password}
autocomplete="new-password"
onChange={pwStrengthCheck}
name="password"
label="*Password" type="password" variant="outlined" size="small"
InputProps={{
  endAdornment: (
    <InputAdornment position="end">
      {pwStrengthOk()?
        <CheckCircleRoundedIcon style={{color:pwColor}} />
        :
        <CircleIcon style={{color:pwColor}} />
      }
    </InputAdornment>
  )
}}


/>

</Box>
<TextField id="confirmPassword"
style={{width:"100%"}}
value={fields.confirmPassword}
onChange={handleFieldChange}
name="confirmPassword"
label="*Confirm Password" type="password" variant="outlined" size="small"
InputProps={{
  endAdornment: (
    <InputAdornment position="end">
      {pwAllOk()?
        <CheckCircleRoundedIcon style={{color:pwColor}} />
        :
        <CircleIcon style={{color:"#DB4437"}} />
      }
    </InputAdornment>
  )
}}




/>
      </Box>  </Paper></Box>

      <FormControlLabel
        control={
          <Checkbox  id="tandc"

            checked={tncs}
            onChange={handleTncsChange}

          />
        }
        label={
<div>
 <span >I accept the </span>
      <Link onClick={tandcs}  className={classes.link} color= 'secondary' hover='secondary' to={'/'}>terms and conditions</Link>

</div>
}
      />
        <LoaderButtonX
          block
          size="lg"
          type="submit"
          variant="success"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Signup
        </LoaderButtonX>
      </Form>

<br/>

















      </div>
    );
  }

  return (
    <div className="Signup">
      {newUser === null ? renderForm() : renderConfirmationForm()}

      <Dialog
      open={alertOpen}
      fullWidth={false}
      maxWidth = {'md'}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      >
      <DialogTitle id="alert-dialog-slide-title" style={{color:"#10385A"}} >
      {alertVals.title}
      </DialogTitle>
      <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
      {alertVals.message}
      </DialogContentText>
      </DialogContent>
      <DialogActions>
      <Button onClick={handleAlertClose} color="secondary">
      Ok
      </Button>
      </DialogActions>
      </Dialog>

    </div>
  );
}
