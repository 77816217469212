import React, { useState, useEffect } from "react";
import {useLocation, useHistory} from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import { Auth, API } from "aws-amplify";
import Form from "react-bootstrap/Form";
import LoaderButtonX from "../components/LoaderButtonX";
//import LoaderButtonTextOnly from "../components/LoaderButtonTextOnly";
//import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import "./Manager.css";

//import Box from '@material-ui/core/Box';


import spinner from'../images/Driving-SVG.gif';

import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import { Link } from 'react-router-dom';


import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const FooterTypography = withStyles({
  root: {
    color: "#fff"
  }
})(Typography);

const useStyles = makeStyles((theme) => ({
  link: {
    color: "#F18C7E",
    "&:hover": {
      color: "#F18C7E",
      textDecoration: "underline #F18C7E"
    }
  }

}));






export default function Manager() {
  const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
  const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
  const [pwColor, setPwColor] = useState("#DB4437");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isForgotPWing, setIsForgotPWing] = useState(false);
  const [type, setType] = useState('');
  const [prompt, setPrompt] = useState('');
  const [sessionValues, setSessionValues] = React.useState([]);
  const location = useLocation();
  const history = useHistory();
  const { isCampra } = useAppContext();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertVals, handleAlertChange] = useFormFields({
    title: "",
    message: "",
    isError: false,
  });


  const [fields, handleFieldChange] = useFormFields({
    code: "",
    email: "",
    newemail: "",
    password: "",
    confirmPassword: "",
    name: "",
    newname: "",
    phone: "",
    newphone: "",
    title: "",
    message: "",
    colour: "red",
    isUnverified: false,
    authUser: null,
    canCancel: true,
  });

  useEffect(() => {
    function onLoad() {
      if (!location.state || !location.state.type || location.state.type==='' || (isCampra && location.state.type!=='password')) {
        history.push("/");
      } else {
        //fields.colour  = 'green';
        switch (location.state.type) {
          case 'password':
          case 'forgot':
          fields.email = location.state.email || '';
          fields.message = 'Create a new password for your email.';
          fields.title = 'Change Password';
          break;
          case 'name':
          fields.name = location.state.name || '';
          fields.message = 'Change the username associated with your account.';
          fields.title = 'Change Username';
          break;
          case 'phone':
          fields.phone = location.state.phone || '';
          fields.message = 'Change the phone number associated with your account.';
          fields.title = 'Change Phone Number';
          break;
          case 'email':
          fields.email = location.state.email || '';
          fields.message = 'Change the email associated with your account. You will need to use the new email to login to your account.';
          fields.title = 'Change Email';
          break;

        }
        setType(location.state.type || '');
      }
    }
    onLoad();
  }, []);


  function pwStrengthCheck(event) {
    if(strongRegex.test(event.target.value)) {
        setPwColor("#0F9D58");
    } else if(mediumRegex.test(event.target.value)) {
      setPwColor("#F4B400");
    } else {
      setPwColor("#DB4437");
    }
    handleFieldChange(event);
  }


  function pwStrengthOk() {
    return strongRegex.test(fields.password);
  }
  function pwMatchOk() {
    return fields.password.length>7 && fields.confirmPassword.length>7 && fields.password===fields.confirmPassword;
  }
  function pwAllOk() {
    return pwStrengthOk() && pwMatchOk();
  }


  function validateEmail() {
    return fields.email.length > 3 && fields.email.indexOf("@")>0 ;
  }
  function validateNewEmail() {
    return fields.newemail.trim() != fields.email && fields.newemail.trim().length > 3 && fields.newemail.trim().indexOf("@")>0 ;
  }
  function validateName() {
    return fields.newname.trim()!=fields.name && fields.newname.trim().length > 2; // and no special chars etc
  }
  function validatePhone() {
    return fields.newphone.trim()!=fields.phone && fields.newphone.trim().length > 7; // and check if all onlyNumbers
  }
  function validateForgotPWForm() {
    return fields.code.length > 3 && fields.password.length >= 1;
  }
  function validateValidationCode() {
    return fields.code.trim().length > 0;
  }

  function handleAlertClose() {
    setAlertOpen(false);
    if (!alertVals.isError) {
      history.goBack();
    }
  };

  function handleCancel() {
    history.goBack();
  }

function updateUser(body) {
  return API.put("findigai", `/users`, {
    body: body
  });
}

async function handleChangeUserAttribute(event) {
  event.preventDefault();
  fields.canCancel=false;
  setIsLoading2(true);
  try {
    var detailsChangeResult = 'SUCCESS';
    const currentUser = await Auth.currentAuthenticatedUser();
    switch (type) {
      case 'name':
      detailsChangeResult = await Auth.updateUserAttributes(currentUser, {
        'custom:name': fields.newname.trim(),
      });
      break;
      case 'phone':
      detailsChangeResult = await Auth.updateUserAttributes(currentUser, {
        'custom:phone': fields.newphone.trim(),
      });
      break;
      case 'email':
      detailsChangeResult = await Auth.updateUserAttributes(currentUser, {
        'email': fields.newemail.trim(),
      });
      break;
    }
    if (detailsChangeResult !== 'SUCCESS') {
      alert("Unable to save changes at the moment, please try again later.")
    } else {
      const cui = await Auth.currentUserInfo();
      const result = await updateUser({ type: 'ACCOUNT', name: cui.attributes['custom:name'], phone: cui.attributes['custom:phone'], email: cui.attributes.email});
      if (type==='email') {
        fields.authUser=currentUser;
        fields.isUnverified=true;
        setIsLoading2(false);
      } else {
        //alert("Change successful.");
        alertVals.title="FinDigAI - Success!";
        alertVals.isError=false;
        alertVals.message="Your account update was successful.";
        setIsLoading2(false);
        setAlertOpen(true);
      }
    }
  } catch (e) {
    //alert(e.message);
    alertVals.title="FinDigAI - Oops";
    alertVals.isError=true;
    alertVals.message=e.message;
    setIsLoading2(false);
    setAlertOpen(true);
  }
}

async function handleChangePassword(event) {
  event.preventDefault();
  fields.canCancel=false;
  setIsLoading2(true);
  Auth.forgotPassword(fields.email)
  .then(data =>
    forgotPasswordSuccess(data))
    .catch(err =>
      forgotPasswordError(err));
    }

    async function handleVerifyEmail(event) {
      event.preventDefault();
      fields.canCancel=false;
      setIsLoading2(true);
      Auth.verifyUserAttributeSubmit(fields.authUser, 'email', fields.code.trim())
      .then(data => validationSubmitSuccess(data))
      .catch(err => validationSubmitError(err));
    }
    function validationSubmitError(err) {
      alertVals.title="FinDigAI - Oops!";
      alertVals.isError=true;
      alertVals.message=err.message;
      setIsLoading2(false);
      setAlertOpen(true);
    }
    function validationSubmitSuccess(data) {
      alertVals.title="FinDigAI - Success!";
      alertVals.isError=false;
      alertVals.message="Your new email has been successfully verified. You will need to use your new email to login.";
      setIsLoading2(false);
      setAlertOpen(true);
    }

    function forgotPasswordError(err) {
      alertVals.title="FinDigAI - Oops!";
      alertVals.isError=true;
      alertVals.message=err.message;
      setIsLoading2(false);
      setAlertOpen(true);
    }
    function forgotPasswordSuccess(forgotData) {
      setIsLoading2(false);
      setType('changePW');
    }

    function handleChangePWSubmit(event) {
      event.preventDefault();

      if (!pwStrengthOk()) {
        alertVals.title="FinDigAI - Oops!";
        alertVals.isError=true;
        alertVals.message=<>Your password strength is not sufficient. Your password should have:<br/>- a minimum of 8 characters<br/>- at least one special character e.g.: !@#$%ˆ&*<br/>- at least one number<br/>- at least one upper case letter<br/>- at least one lower case letter</>;
        setAlertOpen(true);
        return;
      }
      if (!pwMatchOk()) {
        alertVals.title="FinDigAI - Oops!";
        alertVals.isError=true;
        alertVals.message="Your passwords do not match. Please retype.";
        setAlertOpen(true);
        return;
      }
      setIsLoading2(true);
      Auth.forgotPasswordSubmit(fields.email, fields.code, fields.password)
      .then(data => forgotPasswordSubmitSuccess(data))
      .catch(err => forgotPasswordSubmitError(err));
    }
    function forgotPasswordSubmitError(err) {
      alertVals.title="FinDigAI - Oops!";
      alertVals.isError=true;
      alertVals.message=err.message;
      setIsLoading2(false);
      setAlertOpen(true);
    }
    function forgotPasswordSubmitSuccess(data) {
      alertVals.title="FinDigAI - Success!";
      alertVals.isError=false;
      alertVals.message="New password reset successfully!";
      setIsLoading2(false);
      setAlertOpen(true);
    }

    function handleContactClick(event) {
      event.preventDefault();
      history.push("/contactus", { from: 'manager' } );
    }


    function renderLoader() {
      return (
        <div id="splash" style={{paddingTop: 100, zIndex:999}}>
        <center id="splash">
        <img src={spinner} height="30px" width="auto"/>
        </center>
        </div>
      );
    }

    function renderChangePasswordForm() {
      return (
        <>
        <Box display="flex" flexDirection="column" flexWrap="nowrap" my={0}  mx={2} alignItems='top'>
        <Box display="flex" flexWrap="wrap" mb={0}  mx={0} alignItems='top'> <h3>Change Password</h3>  </Box>
        <Box mx={0.5}>
        <p>An email verification code has been sent to your email address. Please enter it below.</p>
        </Box>
        </Box>

        <div className="aaa">


        <Box my={1} >
        <TextField id="code"
        autoFocus
        style={{width:"100%"}}
        value={fields.code}
        onChange={handleFieldChange}
        disabled={false}
        name="code"
        label="*Verification Code" type="number" variant="outlined" size="small"/>
        </Box>



        <Box pb={1}>
  <Paper variant="outlined">
  <Box p={1}>
  <Box pb={1}>
  <TextField id="password"
  style={{width:"100%"}}
  value={fields.password}
  onChange={pwStrengthCheck}
  name="password"
  label="*Password" type="password" variant="outlined" size="small"
  InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        {pwStrengthOk()?
          <CheckCircleRoundedIcon style={{color:pwColor}} />
          :
          <CircleIcon style={{color:pwColor}} />
        }
      </InputAdornment>
    )
  }}


  />

  </Box>
  <TextField id="confirmPassword"
  style={{width:"100%"}}
  value={fields.confirmPassword}
  onChange={handleFieldChange}
  name="confirmPassword"
  label="*Confirm Password" type="password" variant="outlined" size="small"
  InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        {pwAllOk()?
          <CheckCircleRoundedIcon style={{color:pwColor}} />
          :
          <CircleIcon style={{color:"#DB4437"}} />
        }
      </InputAdornment>
    )
  }}




  />
        </Box>  </Paper></Box>
        <LoaderButtonX
        block
        disabled={!validateForgotPWForm()}
        type="button"
        isLoading={isLoading}
        text="Submit new password"
        loadingText="Submitting password…"
        onClick={handleChangePWSubmit}
        />
        </div>
        </>
      );
    }
    function renderForgot() {
      return (
        <>
        <Box display="flex" flexDirection="column" flexWrap="nowrap" my={0}  mx={2} alignItems='top'>
        <Box display="flex" flexWrap="wrap" mb={0}  mx={0} alignItems='top'> <h3>{fields.title}</h3>  </Box>
        <Box mx={0.5}>
        <p>{fields.message}</p>
        </Box>
        </Box>

        <div className="aaa">
        <Paper>
        <Box p={1}>

        {type==='password'?

        <Box width="100%" display="flex" justifyContent="center" alignItems="center">
        <h6 style={{color:"#F18C7E"}} ><b>{fields.email}</b></h6>
        <br/>
        </Box>

        :

        <Box my={1} >
        <TextField id="email"
        autoFocus
        style={{width:"100%"}}
        value={fields.email}
        onChange={handleFieldChange}
        disabled={isLoading2}
        name="email"
        label="*Email" type="email" variant="outlined" size="small"/>
        </Box>
      }

        <LoaderButtonX
        block
        type="button"
        isLoading={isLoading2}
        disabled={!validateEmail()}
        onClick={handleChangePassword}
        >
        Create New Password
        </LoaderButtonX>
        </Box>
        </Paper>
        </div>
        </>
      );
    }
    function renderName() {
      return (
        <>
        <Box display="flex" flexDirection="column" flexWrap="nowrap" my={0}  mx={2} alignItems='top'>
        <Box display="flex" flexWrap="wrap" mb={0}  mx={0} alignItems='top'> <h3>{fields.title}</h3>  </Box>
        <Box mx={0.5}>
        <p>{fields.message}<br/><b>{fields.name}</b></p>
        </Box>
        </Box>

        <div className="aaa">

        <Box my={1} >
        <TextField id="newname"
        autoFocus
        style={{width:"100%"}}
        value={fields.newname}
        onChange={handleFieldChange}
        disabled={isLoading2}
        name="newname"
        label="*New Name" type="text" variant="outlined" size="small"/>
        </Box>

        <LoaderButtonX
        block
        size="lg"
        type="button"
        isLoading={isLoading2}
        disabled={!validateName()}
        onClick={handleChangeUserAttribute}
        >
        Submit New Name
        </LoaderButtonX>
        </div>
        </>
      );
    }
    function renderEmail() {
      return (
        <>
        <Box display="flex" flexDirection="column" flexWrap="nowrap" my={0}  mx={2} alignItems='top'>
        <Box display="flex" flexWrap="wrap" mb={0}  mx={0} alignItems='top'> <h3>{fields.title}</h3>  </Box>
        <Box mx={0.5}>
        <p>{fields.message}<br/><b>{fields.email}</b></p>
        </Box>
        </Box>

        <div className="aaa">


        <Box my={1} >
        <TextField id="newemail"
        autoFocus
        style={{width:"100%"}}
        value={fields.newemail}
        onChange={handleFieldChange}
        disabled={isLoading2}
        name="newemail"
        label="*New Email" type="email" variant="outlined" size="small"/>
        </Box>

        <LoaderButtonX
        block
        type="button"
        isLoading={isLoading2}
        disabled={!validateNewEmail()}
        onClick={handleChangeUserAttribute}
        >
        Submit New Email
        </LoaderButtonX>
        </div>
        </>
      );
    }
    function renderPhone() {
      return (
        <>
        <Box display="flex" flexDirection="column" flexWrap="nowrap" my={0}  mx={2} alignItems='top'>
        <Box display="flex" flexWrap="wrap" mb={0}  mx={0} alignItems='top'> <h3>{fields.title}</h3>  </Box>
        <Box mx={0.5}>
        <p>{fields.message}<br/><b>{fields.phone}</b></p>
        </Box>
        </Box>

        <div className="aaa">


        <Box my={1} >
        <TextField id="newphone"
        autoFocus
        style={{width:"100%"}}
        value={fields.newphone}
        onChange={handleFieldChange}
        disabled={isLoading2}
        name="newphone"
        label="*New Phone Number" type="number" variant="outlined" size="small"/>
        </Box>



        <LoaderButtonX
        block
        type="button"
        isLoading={isLoading2}
        disabled={!validatePhone()}
        onClick={handleChangeUserAttribute}
        >
        Submit New Phone Number
        </LoaderButtonX>
        </div>
        </>
      );
    }

    function renderUnverifiedForm() {
      return (
        <>
        <Box display="flex" flexDirection="column" flexWrap="nowrap" my={0}  mx={2} alignItems='top'>
        <Box display="flex" flexWrap="wrap" mb={0}  mx={0} alignItems='top'> <h3>Verify New Email Address</h3>  </Box>
        <Box mx={0.5}>
        <p>An email verification code has been sent to your email address. Please enter it below.</p>
        </Box>
        </Box>

        <div className="aaa">


        <Box my={1} >
        <TextField id="code"
        autoFocus
        style={{width:"100%"}}
        value={fields.code}
        onChange={handleFieldChange}
        disabled={isLoading2}
        name="code"
        label="*Verification Code" type="number" variant="outlined" size="small"/>
        </Box>
        <LoaderButtonX
        block
        disabled={!validateValidationCode()}
        type="button"
        onClick={handleVerifyEmail}
        isLoading={isLoading2}
        text="Verify Email"
        loadingText="Verifying…"
        />
        </div>
        </>
      );
    }

    return (
      <div className="Manager">
      <Form>
      <div className="lander">
      {isLoading?renderLoader():(fields.isUnverified ? renderUnverifiedForm() : (type==='forgot' || type==='password'?renderForgot():(type==='changePW'?renderChangePasswordForm():(type==='name'?renderName():(type==='email'?renderEmail():(type==='phone'?renderPhone():''))))))}
      {fields.canCancel?
        <div className="aaa">
        <br/>
        <br/>
        <LoaderButtonX
        block
        size="lg"
        isLoading={false}
        disabled={false}
        onClick={handleCancel}
        >
        Cancel
        </LoaderButtonX>
        <br/><br/>
        </div>


        :''}
        </div>
        </Form>



        <Box
        position="sticky"
        bottom="0px"
        left="0px"
        right="0px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
        bgcolor="#10385A">
        <Divider width="100%" style={{ background: '#fff' }}/>


        <Box display="flex" p={1} width="100%">

        <Box p={0} flexGrow={1}>
        <FooterTypography variant="caption">FINDIGAI<sup>®️</sup> LIMITED<br/> {/* ® */}
        Company No. ABC12345<br/>
        <Link style={{color:"white"}} onClick={handleContactClick}>Contact us</Link>
        </FooterTypography>
        </Box>
        <Box p={0} display="flex" flexDirection="column">
        <a style={{color:"white", fontSize:"12px", textAlign:"right"}} href={"https://findigai.com/docsx/faqs.pdf"} target="_blank">FAQs</a>
        <Box style={{textAlign:"right"}} py={0.5}><a style={{color:"white", fontSize:"12px", textAlign:"right"}} href={"https://findigai.com/docsx/privacy.pdf"} target="_blank">Privacy</a></Box>
        <a style={{color:"white", fontSize:"12px", textAlign:"right"}} href={"https://findigai.com/docsx/tncs.pdf"} target="_blank">Terms of Use</a>
        </Box>
        </Box>
        <Box width="100%" mt={-0.8} pl={1} style={{textAlign:"left"}}><a style={{color:"#ccc", fontSize:"10px", textAlign:"left"}} >©2022 FinDigAI Limited. All rights reserved.</a></Box>
        </Box>


        <Dialog
        open={alertOpen}
        fullWidth={false}
        maxWidth = {'md'}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        >
        <DialogTitle id="alert-dialog-slide-title" style={{color:"#10385A"}} >
        {alertVals.title}
        </DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
        {alertVals.message}
        </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleAlertClose} color="secondary">
        Ok
        </Button>
        </DialogActions>
        </Dialog>

        </div>
      );

    }
