import React, { useState, useEffect } from "react";


// should i use auth for user to resubmit their password to make changes (how do i do this?)
//import { Auth } from "aws-amplify";


import { Auth, API } from "aws-amplify";


import Form from "react-bootstrap/Form";
//import LoaderButton from "../components/LoaderButton";
import LoaderButtonX from "../components/LoaderButtonX";

//import LoaderButtonTextOnly from "../components/LoaderButtonTextOnly";
import { useHistory} from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import "./Account.css";

//import Box from '@material-ui/core/Box';

import spinner from'../images/Driving-SVG.gif';
import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

import { Link } from 'react-router-dom';


import EditInput from "../components/EditInput";

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const FooterTypography = withStyles({
  root: {
    color: "#fff"
  }
})(Typography);

const useStyles = makeStyles((theme) => ({
  link: {
      color: "#F18C7E",
      "&:hover": {
          color: "#F18C7E",
          textDecoration: "underline #F18C7E"
      }
    }

}));


export default function Account() {
  const { isCampra, isAuthenticated, setUserName} = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();


  const [fields, handleFieldChange] = useFormFields({
    name: "",
    email: "",
    phone: "",
    message: "",
    colour: "red",
  });


  useEffect(() => {
    async function onLoad() {
      setIsLoading(true);
      try {
        const cui = await Auth.currentUserInfo();
        if (cui) {
          fields.name=cui.attributes['custom:name'];
          fields.email=cui.attributes.email;
          fields.phone=cui.attributes['custom:phone'];
          setUserName(cui.attributes['custom:name']);
          setIsLoading(false);
        } else {
          history.push("/");
        }
      } catch (e) {
        onError(e);
        history.push("/");
      }
    }
    onLoad();
  }, []);

  function validateForm() {
    return fields.email.length > 3 && fields.email.indexOf("@")>0 && fields.password.length > 5;
  }
  function validateForgotPW() {
    return fields.email.length > 0;
  }
  function handleForgotPassword() {
    history.push("/manager", { email: fields.email, type: 'forgot' });
  }

  function getDashboardLink() {
    //return API.get("aires", `/customers/dashboard`);
    return null;
  }


  async function handleSubmit(event) {
    event.preventDefault();

    const result = await getDashboardLink();
    if (result && result.status==true) {
      let url = result.link.url;
      if (url) {
        //window.location.href = url;
      }
    } else {
      // user not found (unlikely)
      history.push("/");
    }
  }

  function handleNameClick() {
    history.push("/account/edit", { name: fields.name, type: 'name' });
  }
  function handleEmailClick() {
    history.push("/account/edit", { email: fields.email, type: 'email' });
  }
  function handlePhoneClick() {
    history.push("/account/edit", { phone: fields.phone, type: 'phone' });
  }
  function handleChangePassword() {
    history.push("/account/edit", { email: fields.email, type: 'password' });
  }

  function handleContactClick(event) {
    event.preventDefault();
    history.push("/contactus", { from: 'account' } );
  }


  function renderLander() {
      return (
        <div className="lander">
        <Box display="flex" flexDirection="column" flexWrap="nowrap" my={0}  mx={2} alignItems='top'>
        <Box display="flex" flexWrap="wrap" mb={0}  mx={0} alignItems='top'> <h3>My Account Details</h3>  </Box>
        <Box mx={0.5}>
          <p></p>
        </Box>
        </Box>

          <Form onSubmit={handleSubmit}>
          <br/>
          <Form.Group size="lg" controlId="name">
             <Form.Label>Name</Form.Label>
             <EditInput
             size="small"
             value={fields.name}
             iDisabled={isCampra}
             disabled={true}
             onClick={() => { handleNameClick() }}
             />
           </Form.Group>
           <Form.Group size="lg" controlId="email">
              <Form.Label>Email</Form.Label>
              <EditInput
              size="small"
              iDisabled={isCampra}
              value={fields.email}
              disabled={true}
              onClick={() => { handleEmailClick() }}
              />
            </Form.Group>
           <Form.Group size="lg" controlId="phone">
             <Form.Label>Phone Number</Form.Label>
             <EditInput
             size="small"
             value={fields.phone}
             iDisabled={isCampra}
             disabled={true}
             onClick={() => { handlePhoneClick() }}
             />
           </Form.Group>
<br/>
          <LoaderButtonX
            block
            size="lg"
            type="button"
            isLoading={isLoading}
            onClick={handleChangePassword}
            disabled={false}
          >
            Change Password
          </LoaderButtonX>

          {/*}
          <br/>
          {!isCampra?<LoaderButton
            block
            size="small"
            type="submit"
            isLoading={isLoading}
            disabled={false}
          >
            Payments Dashboard
          </LoaderButton>
          :""}
          */}

          </Form>
          <br/>
        </div>
      );
    }


    function renderLoader() {
      return (
        <div className="lander" id="splash" style={{paddingTop: 100, zIndex:999}}>
          <center id="splash">
            {/*<img src={spinner} height="30px" width="auto"/>*/}
          </center>
        </div>
      );
    }


  return (
    <div className="Account">
      {isLoading?renderLoader():renderLander()}
      <Box
      position="sticky"
      bottom="0px"
      left="0px"
      right="0px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      bgcolor="#10385A">
      <Divider width="100%" style={{ background: '#fff' }}/>


      <Box display="flex" p={1} width="100%">

      <Box p={0} flexGrow={1}>
      <FooterTypography variant="caption">FinDigAI<sup>®️</sup> Limited<br/> {/* ® */}
      Company No. ABC12345<br/>
      <Link style={{color:"white"}} onClick={handleContactClick}>Contact us</Link>
      </FooterTypography>
      </Box>
      <Box p={0} display="flex" flexDirection="column">
      <a style={{color:"white", fontSize:"12px", textAlign:"right"}} href={"https://findigai.com/docx/faqs.pdf"} target="_blank">FAQs</a>
      <Box style={{textAlign:"right"}} py={0.5}><a style={{color:"white", fontSize:"12px", textAlign:"right"}} href={"https://findigai.com/docx/privacy.pdf"} target="_blank">Privacy</a></Box>
      <a style={{color:"white", fontSize:"12px", textAlign:"right"}} href={"https://findigai.com/docx/tncs.pdf"} target="_blank">Terms of Use</a>
      </Box>
      </Box>
      <Box width="100%" mt={-0.8} pl={1} style={{textAlign:"left"}}><a style={{color:"#ccc", fontSize:"10px", textAlign:"left"}} >©2023 FinDigAI Limited. All rights reserved.</a></Box>
      </Box>


    </div>
  );

}
