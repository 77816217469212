import React, { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import "./Home.css";
//import LoaderButton from "../components/LoaderButton";

import { useAppContext } from "../libs/contextLib";
import { useHistory } from "react-router-dom";
//import bgimage from'../images/large_camper.jpeg';
import spinner from'../images/Driving-SVG.gif';
import campraImage from'../images/splash01.jpg';


import TextField from '@material-ui/core/TextField';
import LoaderButtonX from "../components/LoaderButtonX";
import SiteIdInput from "../components/SiteIdInput";

import { useFormFields } from "../libs/hooksLib";
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Form from "react-bootstrap/Form";
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


import IconButton from '@material-ui/core/IconButton';

//import IconButton from '@mui/material/IconButton';

import AccountBalanceRoundedIcon from '@material-ui/icons/AccountBalanceRounded';
import FollowTheSignsRoundedIcon from '@material-ui/icons/StorefrontRounded';
//import StorefrontRoundedIcon from '@mui/icons-material/StorefrontRounded';
//import SettingsBackupRestoreRoundedIcon from '@mui/icons-material/SettingsBackupRestoreRounded';
import LocalParkingRoundedIcon from '@material-ui/icons/LocalParkingRounded';
import HowToRegRoundedIcon from '@material-ui/icons/HowToRegRounded';
import RestartAltRoundedIcon from '@material-ui/icons/SettingsBackupRestoreRounded';
//import AddLocationAltRoundedIcon from '@material-ui/icons/AddLocationAltRounded';

import { Link } from 'react-router-dom';


import QrReader from 'react-qr-reader'
import Fab from '@material-ui/core/Fab';

import Divider from '@material-ui/core/Divider';


import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';

import iTopOvernight from '../images/preview/Pay Per Night - Blue.svg';
import iTopFacilities from '../images/preview/Facilities Only - Blue.svg';

import { v4 as uuidv4 } from 'uuid';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import AlertIcon from '@material-ui/icons/NotificationsActiveRounded';
import AlertIcon from '@material-ui/icons/AnnouncementRounded';

import CheckIcon from '@material-ui/icons/CheckCircleRounded';
import AddIcon from '@material-ui/icons/AddCircleOutlineRounded';

import { styled } from '@material-ui/core/styles';

import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';


import FDBarChart from "../components/FDBarChart";
import EditorForm from '../components/EditorForm';
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";


import Modal from '../components/Modal.js';

const bcdata = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  }
];


  const labels = ["1", "2", "3", "4", "5", "6"];
  const data = {
    labels: labels,
    datasets: [
      {
        label: "My First dataset",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgb(255, 99, 132)",
        data: [0, 10, 5, 2, 20, 30, 45],
      },
    ],
  };

//import OpenAI from "openai";
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const BlueTooltip = withStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid #10385A"
    },
    color: '#10385Acc'
  },
  tooltip: {
    backgroundColor: '#10385Add',
    color: '#ffffff',
    border: "1px solid #10385A",
    fontSize: 12,
    fontFamily: "Montserrat",
    fontWeight: 400,

  },
}))(Tooltip);




const FooterTypography = withStyles({
  root: {
    color: "#fff"
  }
})(Typography);

const useStyles = makeStyles((theme) => ({


  root: {
    '& > *': {
      margin: theme.spacing(1),
      background: "#000",
    },
  },
  imageList: {
    width: 500,
    height: 48,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  card: {
    margin: theme.spacing(2),
  },
  h4: {
    fontFamily: 'Comfortaa',
    color: '#10385A4'
  },
  h6: {
    color: '#F18C7E'
  },

  dialogContent: {
    width: '100%',
    backgroundColor: '#10385A',
    paddingTop: '80px',
    height: "100vh",
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  divcontrol: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  label: {
    display: "flex"
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    color: 'white'
  },
  pdf: { cursor: 'pointer', marginTop: '-0px', marginRight: '-8px', color: '#10385A4' },

  link: {
    color: "#F18C7E",
    "&:hover": {
      color: "#F18C7E",
      textDecoration: "underline #F18C7E"
    }
  },
  spreadBox: {
    justifyContent: "space-between"
  },

  textField: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red"
    },
    "& .MuiOutlinedInput-input": {
      color: "red"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "red"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "red"
    },
    "& .MuiInputLabel-outlined": {
      color: "red"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "red"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "red"
    },


  }



}
));

export default function Home() {
  //const openai = new OpenAI();
  /*
  constructor(props) {
    super(props);
    this.state = {
       dbArticles: []
    }

    this.handlePractice = this.handlePractice.bind(this);
  }
  */

  


  let classes = useStyles();

  const history = useHistory();
  const { userHasAuthenticated, isAuthenticated, isCampra, setShowSites} = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingContReg, setIsLoadingContReg] = useState(false);
  const [isLoadingReReg, setIsLoadingReReg] = useState(false);
  const [isLoadingPayments, setIsLoadingPayments] = useState(false);
  const [isCheckingAccount, setIsCheckingAccount] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [scanResult, setScanResult] = useState("");
  const [pauseScan, setPauseScan] = useState(false);
  const [accountId, setAccountId] = useState("");
  const [email, setEmail] = useState("");
  const [requiresAccountDetails, setRequiresAccountDetails] = useState(false);
  const [canResetAccount, setCanResetAccount] = useState(false);
  const [canShowSites, setCanShowSites] = useState(false);
  const [addFirstSite, setAddFirstSite] = useState(false);
  const [orderSigns, setOrderSigns] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertRestart, setAlertRestart] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    siteid: "",
    reg: "",
  });
  const [alertVals, handleAlertChange] = useFormFields({
    title: "",
    message: "",
  });

  const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
      const handleWindowResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
  }

  const [isInit, setInit] = useState(false);
  const [sites, setSites] = useState([]);
  const [tabSite, setTabSite] = useState(0);
  const [projects, setprojects] = useState([]);
  const [userName, setUserName] = useState("");

  const [isModalOpen, setModalOpen] = useState(false);

  const { width } = useViewport();
  const breakpoint = 600;

  //const [assetTypes, setAssetTypes] = useState([{id:1,name:"Public Equity"},{id:2,name:"Private Equity"},{id:3,name:"Senior Debt"}]);
  const assetTypes =[{id:1,name:"Public Equity"},{id:2,name:"Private Equity"},{id:3,name:"Private Debt"}];

  const [expanded, setExpanded] = React.useState([]);

  const handleExpandClick = (idx, event) => {
    event.stopPropagation();
    event.preventDefault();
    console.log(event);
    const curr = expanded[idx];
    //let newArr = new Array(data.length).fill(false);
    let newArr = new Array(100).fill(false);
    newArr[idx] = !curr;
    setExpanded(newArr);
  };



  const handleProjectClick = (project, event) => {
    event.preventDefault();
    console.log(project);
  };

  const handleAddClick = (event) => {
    event.preventDefault();
    console.log(event);
    setModalOpen(true)
  };

  const handleEditorFieldChange = (param) => {
    console.log("handleEditorFieldChange: ", param);
  };
  const handleEditorClose = (param) => {
    setModalOpen(false)
  };
  const handleEditorSave = (param) => {
    console.log("handleEditorSave-: ", param);

    createProject(param);





   

    


  };
 
  const myFunction = (param) => {
    console.log("do something: ", param);
  };
 


  async function  createProject(data) {
    
    const newData = {orgId: accountId, name: data.pName, description: data.pDescription, assetType: Number(data.pType), userName: userName}
    console.log(newData);
    const result = await createNewProject(newData);
    console.log(result);


    const resultProjects = await getProjects({orgId: accountId});

    console.log(resultProjects)

    setprojects(resultProjects.data);
    setModalOpen(false);


  
}
function getProjects(body) {
  return API.post("findigai", "/projects", {
    body: body
  });
}

function createNewProject(body) {
  return API.post("findigai", "/projects/create", {
    body: body
  });
}



useEffect(() => {

  async function onLoad() {
    if (!isAuthenticated) {history.push("/"); return;}
    //lock("portrait");
    setIsCheckingAccount(true);
    const cui = await Auth.currentUserInfo();
    if (!(cui && cui.attributes)) {
      userHasAuthenticated(false);
      history.push("/");
    } else {
      const resultUser = await createUser({userName: cui.attributes['custom:name'], phone: cui.attributes['custom:phone'], email: cui.attributes.email});
      setEmail(resultUser.user.email);
      let orgId = resultUser.user.orgId;
/*
      if (!(accId && accId!="")) {
        const resultAccount = await createCustomer({email: resultUser.user.email});
        accId = resultAccount.account.id;
      }
*/
      setAccountId(orgId);
      setUserName(resultUser.user.userName);
      
console.log(uuidv4())
console.log(resultUser)


      const resultProjects = await getProjects({orgId: orgId});

      console.log(resultProjects)

      setprojects(resultProjects.data);




      setSites([{content:{name:"aaa", projects:[{name:"zzz", date: 1723456789012, owner:"qqq"}, {name:"zzz", date: 1723456789012, owner:"qqq"},{name:"zzz", date: 1723456789012, owner:"qqq"}]}},{content:{name:"bbb", projects:[{name:"zzzz", date: 1723456789012, owner:"qqq"}, {name:"zzz", date: 1723456789012, owner:"qqq"},{name:"zzz", date: 1723456789012, owner:"qqq"}]}},{content:{name:"ccc", projects:[{name:"zzzzz", date: 1723456789012, owner:"qqq"}, {name:"zzzzzz", date: 1723456789012, owner:"qqq"},{name:"zzzzzzz", date: 1723456789012, owner:"qqq"}]}},]);



/*
      const completion = await openai.chat.completions.create({
        messages: [{ role: "system", content: "You are a helpful assistant." }],
        model: "gpt-3.5-turbo",
      });
      console.log(completion.choices[0]);
*/

      /*
      const parts = window.location.href.split("?");
      if (parts.length > 1) {
        if (parts[1].indexOf('reauth=true')>-1) {
          const resultLink = await linkCustomer({id: accId, url: window.location.protocol + "//" + window.location.host});
          let url = resultLink.accountLinks.url;
          if (url) {
            window.location.href = url;
          }
          return;
        }
      }
      
      const result = await getAccount(accId);
      if (!result.account.charges_enabled || !result.account.details_submitted || result.account.requirements.currently_due.length>0) {
        setRequiresAccountDetails(true);
        setCanShowSites(false);
        setShowSites(false);
        if (resultUser.user.itemStatus && resultUser.user.itemStatus > 0) {
          setCanResetAccount(false);
        } else {
          if (result.account.capabilities.card_payments && result.account.capabilities.transfers) {
            setCanResetAccount(true);
          } else {
            setCanResetAccount(false);
          }
        }
      } else {
        setRequiresAccountDetails(false);
        const result = await getSites();
        if (result.status && result.data && result.data.length>0) {
          result.data.forEach(site => {
            if (!(site.itemStatus2 && site.itemStatus2===1)) {
              setOrderSigns(true);
            }
          });
          setCanShowSites(true);
          setShowSites(true);
        } else {
          setCanShowSites(false);
          setShowSites(false);
          setAddFirstSite(true);
        }
      }
      */
    }
    setIsCheckingAccount(false);
    setInit(true);
  }
  onLoad();
}, []);


function getAccount(accId) {
  return API.get("findigai", `/customers/${accId}`);
}



async function handleContinueRegistrationClick() {
  setIsLoadingContReg(true);
  setIsLoading(true);
  const resultLink = await linkCustomer({id: accountId, url: window.location.protocol + "//" + window.location.host});
  let url = resultLink.accountLinks.url;
  if (url) {
    window.location.href = url;
  }
  return;
  setIsLoading(false);
  setIsLoadingContReg(false);
}



function handleResetAccountClick(e) {
  e.preventDefault();
  setAlertRestart(true);
}

function handleAddFirstSiteClick(e) {
  e.preventDefault();
  history.push("sites/create/new");
}





async function resetAccount() {
  setIsLoadingReReg(true);
  setIsLoading(true);
  setAlertRestart(false);
  const resultLink = await recreateCustomer({previousAccountId: accountId, email: email, url: window.location.protocol + "//" + window.location.host});
  let url = resultLink.accountLinks.url;
  if (url) {
    window.location.href = url;
  }
  return;
  setIsLoading(false);
  setIsLoadingReReg(false);
}







function validateForm() {
  return !(fields.siteid.length > 0);
}


function handleScanClick(e) {
  setPauseScan(false);
  //setScanResult("CLEARED");
  setIsScanning(true);
}

function handleScanError(e) {
  setIsScanning(false);
  alertVals.title = "QR Code Scanner"
  if (e.code==8) {
    alertVals.message = "Camera not found."
  } else {
    alertVals.message = e.message;
  }
  setAlertOpen(true);
}

function handleScan(data) {
  if (!pauseScan && data) {
    setPauseScan(true);
    const parts=data.split("/");
    /*
    if (parts.length==5) {
      if (parts[2].indexOf("afindigai.com")>-1) {
        const siteId=parts[parts.length-1];
        openSite(siteId);
        setIsScanning(false);
      }
    }
    */
    setPauseScan(false);
  }
}

function handleSiteClick() {
  const siteId = fields.siteid.trim().toUpperCase();
  //openSite(siteId);
}


async function openSite(siteId) {
  if (siteId.length>0) {
    setIsLoading(true);
    const result = await getSite(siteId);
    if (result.status==true) {
      if (isCampra) {
        history.push("/campra/" + siteId);
      } else {
        history.push("/sites/" + siteId);
      }
    } else {
      alertVals.title = "FinDigAI - Oops!"
      alertVals.message = "Unable to find a company with that code."
      setAlertOpen(true);
    }
    setIsLoading(false);
  }
}




function handleSitesClick() {
  history.push("/sites");
}
function handleSignShopClick() {
  history.push("/shop");
}

function handleVisitorBookClick() {
  history.push("/visitors");
}








async function handleEmailClick() {
  setIsLoading(true);
  let result = await testmail();
  setIsLoading(false);
}


function cancelScanClick() {
  setIsScanning(false);
}


function testmail() {
  return API.post("findigai", "/mail", {
    body: {}
  });
}



function getSite(code) {
  return API.get("aires", `/sites/${code}/EXISTS`);
}

// create user if not already exists then returns user
function createUser(body) {
  return API.post("findigai", "/users", {
    body: body
  });
}


function createCustomer(content) {
  return API.post("aires", "/customers", {
    body: {type: 'create', content: content}
  });
}
function recreateCustomer(content) {
  return API.post("aires", "/customers", {
    body: {type: 'recreate', content: content}
  });
}
function linkCustomer(content) {
  return API.post("aires", "/customers", {
    body: {type: 'link', content: content}
  });
}

function handleAlertClose() {
  setAlertOpen(false);
  setAlertRestart(false);
};

function getDashboardLink() {
  //return API.get("aires", `/customers/dashboard`);
  return null;
}

async function handlePaymentsClick(event) {
  event.preventDefault();
  setIsLoadingPayments(true);
  setIsLoading(true);
  const result = await getDashboardLink();
  if (result && result.status==true) {
    let url = result.link.url;
    if (url) {
      window.location.href = url;
    }
  } else {
    // user not found (unlikely)
    history.push("/");
  }
  setIsLoading(false);
  setIsLoadingPayments(false);
}

const eventhandler = data => {fields.siteid=data.siteid }

function handleContactClick(event) {
  event.preventDefault();
  history.push(isAuthenticated?"/contactus":"/contact", { from: 'home' } );
}


function handleTabChange(event, value) {
  setTabSite(value);
};
/*
function renderEditor() {
  //const { t} = this.props
  let layer = null
  let dest = null
  let title = ''
  let showMap = false
  let table = ''
  let multiLine = null


  title = "Create New Project"
  
  return (
    <EditorForm
      sx={{
        position: 'absolute',
        top: '0px',
        left: '0px',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > :not(style)': {
          display: 'flex',
          flexDirection: 'column',
          m: 1,
          width: showMap ? 1200 : 600,
          height: 'calc(100vh - 100px)',
        },
      }}
      title={title}
      layer={layer}
      showMap={showMap}
      multiLine={['comments']}
      feature={dest}
      hideList={[]}
      onChange={handleEditorFieldChange}
      onCancel={handleEditorClose}
      onSave={handleEditorSaveClick}
      //myRef={editorRef}
      table={table}
    />
  )
}
*/

function renderLander() {
  return (
    <div className={width>breakpoint?"lander":"mobile-lander"} >

    <h1>Intelligent</h1>
    <h1>Financial</h1>
    <h1>Reporting</h1>


    <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    width="100%"
    position="absolute"
    bottom="60px"
    left="0px"
    right="0px"

    >
    <SiteIdInput id="siteid"
    onChange={eventhandler}
    size="small"
    onClick={() => { handleSiteClick() }}
    />
    <Box p={1}>
    <Fab size="small" color="primary" aria-label="add" variant="extended" onClick={() => { handleScanClick() }}>
    <span class="material-icons-round" style={{ color: "#fff"}}>
      qr_code_scanner
    </span>
    <span style={{ color: "#fff"}}>
    &nbsp;Scan QR CODE
    </span>

    </Fab>
    </Box>
    </Box>

    <Box
    position="absolute"
    top="calc(100vh + 20px)"
    left="0px"
    right="0px"

    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    width="100%"
    >
    <br/>
    <Box display="flex">

    <h1 style={{color:"#F18C7E", fontSize: "29px"}}>Welcome to&nbsp;</h1>
    
    <h1 style={{color:"#10385A", fontSize: "29px"}}>FinDigAI</h1>
    </Box>
    <Box maxWidth="300px">

      <p>For more information on a listed company, enter the stock exchange code in the search bar above.<br/><br/>
      To find out more about us, or to register interest, visit our menu at the top of the page.</p>
    
    </Box>

    <br/><br/>

    <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    width="100%"
    bgcolor="#10385A">
    <Divider width="100%" style={{ background: '#fff' }}/>

    <Box display="flex" px={1} pt={1} pb={0.5} width="100%"
>




    <Box p={0} flexGrow={1}>
    <FooterTypography variant="caption">FinDigAI<sup>®️</sup> Limited<br/> {/* ® */}
    Company No. ABC12345<br/>
    </FooterTypography>
    <Link style={{color:"white"}} onClick={handleContactClick}>Contact us</Link>
    </Box>
    <Box p={0} display="flex" flexDirection="column"
>
    <a style={{color:"white", fontSize:"12px", textAlign:"right"}} href={"https://findigai.com/docx/faqs.pdf"} target="_blank">FAQs</a>
    <Box style={{textAlign:"right"}} py={0.5}><a style={{color:"white", fontSize:"12px", textAlign:"right"}} href={"https://findigai.com/docx/privacy.pdf"} target="_blank">Privacy</a></Box>
    <a style={{color:"white", fontSize:"12px", textAlign:"right"}} href={"https://findigai.com/docx/tncs.pdf"} target="_blank">Terms of Use</a>
    </Box>

</Box>
<Box width="100%" mt={-0.6} pl={1} style={{textAlign:"left"}}><a style={{color:"#ccc", fontSize:"10px", textAlign:"right"}} >©2023 FinDigAI Limited. All rights reserved.</a></Box>
    </Box>
    </Box>








    </div>






  );




}


function renderLander2() {
  return (
    <>
    <div className={width>breakpoint?"lander2":"mobile-lander2"} >
    {isCheckingAccount?
    <Box display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
        position="absolute"
        top="70px"
        left="0px"
        right="0px"
        >

    <div id="splash" style={{paddingTop: 0, zIndex:999}}>

    <center id="splash">
    {/*<img src={spinner} height="30px" width="auto"/>*/}
    </center>
    </div>
    </Box>
    :""}
    <Box sx={{
          display: 'flex',
          flexDirection: 'row',
        }}>
    <h1 style={{color:"#6666CC"}}>Projects Hub</h1>
    <Box sx={{
ml:2,
mt:0.2
        }}>
   
    <Tooltip title="create new project">
    <AddIcon ml={2}
             disabled={false}
                onClick={handleAddClick}
                style={{ fontSize: 40, color: "#119911" }}                 
             />
             </Tooltip>
             </Box>   </Box>


             <Modal show={isModalOpen} handleClose={handleEditorClose}  handleSave={handleEditorSave} assetTypes={assetTypes} />
        


{/*
    <Box my={2}>
    <p>This is the landing page for an authorised (signed up) user where all features will be available...</p>
    </Box>
    */
}
    <Box display="flex" width="100%" flexDirection="column" justifyContent="center" alignItems='center'>
          {sites.length > 1 ? renderProjects() : null}
   </Box>





    <Box p={0}
    display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width="100%"

>
<Box p={0} style={{maxWidth: "300px"}}>





{/*}

      <Box p={1}>
      <LoaderButtonX
      type="button"
      isLoading={false}
      disabled={!canShowSites}
      onClick={!isLoading ? handleSitesClick : null}
      >
      My AireStops
      </LoaderButtonX>
<Box pt={1}>
      <LoaderButtonX
        type="button"
        isLoading={false}
        disabled={!canShowSites}
        onClick={!isLoading ? handleSignShopClick : null}
      >
        Sign Shop
      </LoaderButtonX>
</Box>
<Box py={1}>
<LoaderButtonX
      type="button"
      isLoading={false}
      disabled={!canShowSites}
      onClick={!isLoading ? handleVisitorBookClick : null}
      >
      Visitor Book
      </LoaderButtonX>
      </Box>
    <LoaderButtonX
      type="button"
      isLoading={isLoadingPayments}
      disabled={!canShowSites}
      onClick={!isLoading ? handlePaymentsClick : null}
    >
      Payments History
    </LoaderButtonX>
    </Box>
    */}
    </Box></Box>

      </div>

      <Box
      position="sticky"
      bottom="0px"
      left="0px"
      right="0px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      bgcolor="#10385A">
      <Divider width="100%" style={{ background: '#fff' }}/>


      <Box display="flex" p={1} width="100%">

      <Box p={0} flexGrow={1}>
      <FooterTypography variant="caption">FinDigAI<sup>®️</sup> Limited<br/> {/* ® */}
      Company No. ABC12345<br/>
      <Link style={{color:"white"}} onClick={handleContactClick}>Contact us</Link>
      </FooterTypography>
      </Box>
      <Box p={0} display="flex" flexDirection="column">
      <a style={{color:"white", fontSize:"12px", textAlign:"right"}} href={"https://findigai.com/docx/faqs.pdf"} target="_blank">FAQs</a>
      <Box style={{textAlign:"right"}} py={0.5}><a style={{color:"white", fontSize:"12px", textAlign:"right"}} href={"https://findigai.com/docx/privacy.pdf"} target="_blank">Privacy</a></Box>
      <a style={{color:"white", fontSize:"12px", textAlign:"right"}} href={"https://findigai.com/docx/tncs.pdf"} target="_blank">Terms of Use</a>
      </Box>
      </Box>
      <Box width="100%" mt={-0.8} pl={1} style={{textAlign:"left"}}><a style={{color:"#ccc", fontSize:"10px", textAlign:"right"}} >©2023 FinDigAI Limited. All rights reserved.</a></Box>
      </Box>


</>
    );
  }



  function renderProjects() {

    return (
      <>


        <Box sx={{
                  width: '100%',
                 bgcolor: '#ffffff00',
                 color: 'white',
               }}> 
          <Tabs
            value={tabSite}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="false"
          >
            {assetTypes.map((type, index) => {
              return (<Tab label={type.name} sx={{
                '&.Mui-selected': {
                    outline: 'none',
                }
            }}/>)
            })}
          </Tabs>
          <Box p={2} sx={{
                  width: '100%',
                 bgcolor: 'white',
                 color: 'white',
               }}> 
          {sites && sites.length > 1 && tabSite >= 0 && renderProjectLists()}
          
          </Box>
          </Box>
      </>
    );
  }





{/*}
  createdAt
  : 
  1708951814
  createdBy
  : 
  "us-east-1:cf8812dd-ca5e-4faf-8143-f2adc796bbb4"
  createdByName
  : 
  "Andrew Rayner"
  description
  : 
  "Project description etc..."
  id
  : 
  1
  name
  : 
  "Campbell Soup Co"
  pk
  : 
  "07d53e99-80ed-4a64-b171-ef6ac608048c"
  sk
  : 
  "PRJ|DTL|1"
  type
  : 
  1
  updatedAt
  : 
  1708951814
  updatedBy
  : 
  "us-east-1:cf8812dd-ca5e-4faf-8143-f2adc796bbb4"
  updatedByNa

*/}
  

function renderProjectLists() {
return (
<>

 
      <Grid container spacing={3} sx={{ flexGrow: 1 }}>
      {projects.filter(project => project.type == assetTypes[tabSite].id).map((proj, index) => (
        
        <Grid item xs={12} onClick={(e) => {handleProjectClick(proj,e) }} >
          <Card className={classes.paper}  style={{backgroundColor: "#6666CC09"}} >
          <Box sx={{ position: 'relative' }}>
             <Box
             py={0.2}
             px={1}
               sx={{
                cursor: 'pointer',
                 width: '100%',
                 bgcolor: '#6666CC55',
                 color: 'white',
               }}
             >
               <Typography variant="caption" color="primary"><b>Status:</b> {proj.itemStatus==0?"cancelled":proj.itemStatus==1?"pre-investment":proj.itemStatus==2?"deactivated":"deferred"}</Typography>
               </Box> </Box>

            <Box pt={2} px={2}>
          <Grid sx={{ flexGrow: 1 }} container spacing={2}  >
          <Grid item xs>  

 
<Box sx={{
          display: 'flex',
          flexDirection: 'row',
          cursor: 'pointer',

        }}>
              {index==1?<Box my={-1.5}   ><AlertIcon fontSize="small" style={{ color: "#ff3333" }}/></Box>:<Box my={-1.5}   ><CheckIcon fontSize="small" style={{ color: "#55aa55" }}/></Box>}
              <Typography variant="h5" color="primary">{proj.name}</Typography>
              </Box>
             </Grid>
             <Grid item xs>  


{proj.scores!=null? <FDBarChart yourFunction={myFunction} data={proj.scores}/>:<></>}

             </Grid>

             <Grid item xs>  


            </Grid>
             <Grid item xs>  
             <Box pt={0.7}
        sx={{
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'row',
     
        }}
      >
             <Typography variant="caption text" color="secondary">last updated:&nbsp;</Typography>
             <Typography variant="caption text" color="primary"><b>{new Date(Number(proj.createdAt)).toLocaleDateString(undefined, { year: "numeric", month: "numeric", day: "numeric" }).replace(/\//g, '-')}</b></Typography>
             </Box>
             </Grid>

             <Grid item xs> 
             <CardActions disableSpacing>
             <ExpandMore
             disabled={false}
             color="secondary"
               expand={expanded[index]}
               onClick={(e) => { handleExpandClick(index, e) }}
               aria-expanded={expanded[index]}
               aria-label="show more"
                size="small"
              id={index}
             >
               <ExpandMoreIcon />
             </ExpandMore>
              </CardActions>
              

              </Grid>





             </Grid>
             
             </Box>
            
             
             <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
            
                <CardContent>
                <Box mt={-2.5}  sx={{display: 'flex', flexDirection: 'row',cursor: 'pointer'}}>
                <Typography variant="body2" color="text.secondary">
                  {proj.description}
                </Typography>
                </Box>
                
                  {/*}
                <List dense={true}>
                  {site.content.links && site.content.links.map((link, idx) => (
                <ListItem onClick={() => { handleDocLinkClick(site.content.id, link.url) }}
                button
                divider
                >
                  <ListItemIcon m={0} p={0}>
                    {link.linkType=="doc"?<DocIcon />:link.linkType=="img"?<ImageIcon />:link.linkType=="pdf"?<PdfIcon />:<DocIcon />}
                  </ListItemIcon>
                  <ListItemText

                    primary={<Typography variant="body2" style={{ color: '#0cb9c2' }}>{link.title}</Typography>}
                    secondary={<Typography variant="caption" style={{ color: '#555' }}>{link.url}</Typography>}
                  />
                </ListItem>
              ))}
                </List>
                */}
                 <Box pr={2} pt={1} sx={{display: 'flex', flexDirection: 'row', justifyContent: "flex-end", width: '100%', cursor: 'pointer'}}>
                    <Typography variant="caption text" color="secondary">project owner:&nbsp;</Typography>
                    <Typography variant="caption text" color="primary"><b>{proj.createdByName}</b></Typography>
                </Box>
               
                </CardContent>
               
              </Collapse>
              
          </Card>
         
        </Grid>
        
        ))}
       
      </Grid>
  




</>
)}


 

























        function renderQrReader() {
          return (
            <div>
            <Paper style={{padding: '4px'}}>
            <QrReader
            delay={300}
            onError={handleScanError}
            onScan={handleScan}
            style={{ width: '100%' }}
            />
            </Paper>
            <Box p={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
            >

            {scanResult}



            <Button

            type="button"
            size="sm"
            variant="outlined"
            isLoading={false}
            disabled={false}
            style={{width: "50%"}}
            onClick={cancelScanClick}
            >
            Cancel
            </Button>
            </Box>
            </div>
          )
        }





        function renderLoader() {
          return (
            <div id="splash" style={{paddingTop: 100, zIndex:999}}>

              <center id="splash">
               {/*} <img src={spinner} height="30px" width="auto"/>*/}
              </center>
            </div>


          );

        }

        return (
          <div className="Home">
          {isAuthenticated ?renderLander2():(isScanning?renderQrReader():(isLoading?renderLoader():renderLander()))}
          <Dialog
          open={alertOpen}
          fullWidth={false}
          maxWidth = {'md'}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          >
          <DialogTitle id="alert-dialog-slide-title" style={{color:"#10385A"}} >
          {alertVals.title}
          </DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          {alertVals.message}
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={handleAlertClose} color="secondary">
          Ok
          </Button>
          </DialogActions>
          </Dialog>
          <Dialog
          open={alertRestart}
          fullWidth={false}
          maxWidth = {'md'}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          >
          <DialogTitle id="alert-dialog-slide-title" style={{color:"#10385A"}} >
          AireStop - Restart Registration
          </DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          Are you sure you wish to restart your registration. All existing registration details will be deleted.
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={handleAlertClose} color="primary">
          Cancel
          </Button>
          <Button onClick={resetAccount} color="secondary">
          Restart Registration
          </Button>
          </DialogActions>
          </Dialog>

          </div>
        );
      }
