import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import Form from "react-bootstrap/Form";
import LoaderButtonX from "../components/LoaderButtonX";
import LoaderButtonTextOnly from "../components/LoaderButtonTextOnly";
import { useHistory} from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import "./Login.css";

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';


import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const FooterTypography = withStyles({
  root: {
    color: "#ccc"
  }
})(Typography);

const useStyles = makeStyles((theme) => ({
  link: {
      color: "#F18C7E",
      "&:hover": {
          color: "#F18C7E",
          textDecoration: "underline #F18C7E"
      }
    }

}));







export default function Login() {
  const { userHasAuthenticated, userIsCampra, setUserName } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isForgotPWing, setIsForgotPWing] = useState(false);
  const [sessionValues, setSessionValues] = React.useState([]);
  const history = useHistory();

  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    message: <>Not yet a registered for Intelligent Financial Reporting? <br/><a style={{color: "#F18C7E"}} href="/signup">Signup</a> here.</>,
    colour: "#10385A",
    confirmed: true,
    resent: false,
  });



  useEffect(() => {
    const parts = window.location.href.split("?");
    if (parts.length > 1 && sessionValues.length==0) {
      if (parts[1].indexOf('userName=')>-1 && parts[1].indexOf('confirmation_code=')>-1) {
        const vals = parts[1].split("&");
        const username = vals[0].split("=")[1];
        const confCode = vals[1].split("=")[1];
        submitConfirmationCode(username, confCode);
      }
    }
  });

  async function submitConfirmationCode(email, code) {
    setSessionValues([email, code]);
    setIsLoading(true);

    try {
      await Auth.confirmSignUp(email, code);
      fields.email = email;
      fields.colour  = 'green';
      fields.message = "Thank you, your email is confirmed. You may now login.";
    } catch (e) {
      if (e.code === 'ExpiredCodeException') {
        fields.email = email;
        fields.confirmed = false;
        fields.colour  = 'red';
        fields.message = "Your verification link has expired. Please click the button below to have a fresh verification link sent to your email.";
      } else if (e.code === 'NotAuthorizedException' && e.message == 'User cannot be confirmed. Current status is CONFIRMED') {
        fields.email = email;
        fields.colour  = 'red';
        fields.message = "Not authorized. Please contact FinDigAI admin to resolve this.";
      } else {
        fields.email = email;
        fields.colour  = 'red';
        fields.message = e.message;
      }
    }
    setIsLoading(false);
  }


  function validateForm() {
    return fields.email.length > 3 && fields.email.indexOf("@")>0 && fields.password.length > 5;
  }
  function validateForgotPW() {
    return fields.email.length > 0;
  }
  function handleForgotPassword() {
    history.push("/forgot", { email: fields.email, type: 'forgot' });
  }


  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);
    if (fields.confirmed) {
      try {
        const signinResult = await Auth.signIn(fields.email, fields.password);
        const cui = await Auth.currentUserInfo();
        if (cui.attributes.email_verified==false) {
          await Auth.signOut();
          fields.colour  = 'red';
          fields.message = "Please first confirm your email address by clicking the link that was sent to your email when you registered. It may be in your spam folder if you cannot find it. If you would like the link resent please click the button below.";
          fields.confirmed = false;
          setIsLoading(false);
        } else {
          userHasAuthenticated(true);
          setUserName(cui.attributes['custom:name']);
          userIsCampra(cui.attributes['custom:name']==="CAMpRA" && cui.attributes.email.length==21 && cui.attributes.email.includes("pra@aires"));
        }
      } catch (e) {
        if (e.code === 'UserNotConfirmedException') {
          fields.colour  = 'red';
          fields.message = "Please first confirm your email address by clicking the link that was sent to your email when you registered. It may be in your spam folder if you cannot find it. If you would like the link resent please click the button below.";
          fields.confirmed = false;
          setIsLoading(false);
        } else if (e.code === 'NotAuthorizedException') {
          fields.colour  = 'red';
          fields.message = "Your email address and/or password are incorrect. Please try again with a valid email and password.";
          fields.confirmed = true;
          setIsLoading(false);
        } else {
          fields.confirmed = true;
          setIsLoading(false);
          onError(e);
        }
      }
    } else {
      const aaa = await Auth.resendSignUp(fields.email);
      fields.colour  = 'green';
      fields.message = "Thank you, an email with a link to confirm your email address has been sent to you. Please open this email and click on the link to confirm your email address.";
      fields.confirmed = false;
      fields.resent = true;
      setIsLoading(false);
    }
  }

  return (
    <div className="Login">
    <div className="lander">
    <Box display="flex" flexDirection="column" flexWrap="nowrap" my={0}  mx={2} alignItems='top'>
    <Box display="flex" flexWrap="wrap" mb={0}  mx={0} alignItems='top'> <h3>Host Login</h3>  </Box>
    <Box mx={0.5}>
    <p style={{color: fields.colour}}>{fields.message}</p>
    </Box>
    </Box>

    <Form onSubmit={handleSubmit}>

    {fields.confirmed?
      <>


      <Box my={1}>
      <TextField id="email"
      autoFocus
      style={{width:"100%"}}
      value={fields.email}
      onChange={handleFieldChange}
      name="email"
      label="Email" type="email" variant="outlined" size="small"/>
      </Box>


      <Box pb={1}>
      <TextField id="password"
      style={{width:"100%"}}
      value={fields.password}
      autocomplete="current-password"
      onChange={handleFieldChange}
      name="password"
      label="Password" type="password" variant="outlined" size="small"
      />
      </Box>


      <LoaderButtonX
      block
      size="lg"
      type="submit"
      isLoading={isLoading}
      disabled={!validateForm()}
      >
      Login
      </LoaderButtonX>




      <br/>
      <Box p={2}
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      >
      <LoaderButtonTextOnly

      isLoading={isForgotPWing}
      onClick={handleForgotPassword}
      disabled={false}
      text="Forgot password?"
      loadingText="Forgot password…"

      />
      </Box>

<br/>



      </>
      : (!fields.resent?
        <>
        <LoaderButtonX
        bsStyle="secondary"
        disabled={false}
        type="submit"
        isLoading={isLoading}
        text="Resend Link"
        loadingText="Resending link…"
        />
        <br/>
        </>
        :"")
      }

      </Form>
      </div>


      </div>
    );
  }
