const config = {
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://m3ty9q7i0j.execute-api.us-east-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_IkIVtqMKx",
    APP_CLIENT_ID: "71p1b1fq36rsd3hnq651r5m53s",
    IDENTITY_POOL_ID: "us-east-1:7a0d444a-bd85-43cb-be34-9f8c5b7f836b",
  },
};

export default config;
